<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { isMobile } from 'is-mobile'

export default {
  name: 'SignIn',
  components: {
    Action: () => import('@/components/general/Action'),
    InputField: () => import('@/components/general/InputField'),
    Logo: () => import('@/components/general/Logo')
  },

  data () {
    return {
      username: null,
      password: null,
      isDesktop: null
    }
  },

  validations: {
    username: {
      required
    },

    password: {
      required
    }
  },

  computed: {
    ...mapState([ 'accessibility' ]),
    ...mapGetters([ 'isUserRoot' ])
  },

  created () {
    this.isDesktop = !isMobile()
  },

  methods: {
    ...mapActions([
      'setFeedback',
      'setFetching',
      'attemptSignIn',
      'attemptGetLoggedUser',
      'attemptGetActiveModulesList'
    ]),

    submit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        const { username, password } = this

        this.setFetching(true)

        this.attemptSignIn({
          username,
          password
        })
          .then(() => {
            this.attemptGetLoggedUser().then(() => {
              this.attemptGetActiveModulesList({
                query: {},
                filter: {},
                limit: 20
              }).then(() => {
                this.$router.push({ name: (this.isUserRoot ? 'modules.index' : 'solutions.index') })
              }).finally(() => {
                this.setFetching(false)
              })
            })
          })
          .catch(() => {
            this.setFeedback({ message: this.$t('global:auth.error') })
            this.resetForm()
            this.setFetching(false)
          })
      }
    },

    resetForm () {
      this.$v.$reset()
      this.username = null
      this.password = null
    }
  }
}
</script>

<template>
  <div class="main-content auth">
    <img
      src="/assets/images/themes/default/png/auth_bg.png"
      class="bg-image"
    >
    <div class="center clearfix">
      <div class="form">
        <form
          class="login-form"
          @submit.prevent="submit"
        >
          <InputField
            v-model="username"
            :label="$t('global:form.username')"
            type="text"
            autocomplete="username"
            autocapitalize="none"
            :validation="$v.username"
            dark
          />
          <InputField
            v-model="password"
            :label="$t('global:form.password')"
            type="password"
            autocomplete="current-password"
            :validation="$v.password"
            dark
          />
          <div class="form-submit">
            <Action
              v-if="isDesktop"
              type="button"
              :text="$t('auth.signin:form.submit')"
              submit
              secondary
              large
              fixed-width
              dark
            />
          </div>
        </form>
      </div>
      <div class="company">
        <Logo
          class="company-logo"
          :theme="$theme"
        />
        <!--
          <p class="company-headline" v-html="$t('auth.signin:company.description')"></p>
        -->
      </div>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/auth.css"></style>
